/* CSS for the sidebar container */
.sidebar {
  position: fixed;
  height: 100%;
  height: -webkit-fill-available;
  width: fit-content;
  min-width: 150px;
  left: 0px;
  top: 0;
  border-right: rgba(255, 255, 255, 0.644) 1px solid;
  z-index: 1;
  transition: 0.15s all ease-in;
  overflow-x: hidden;
}

/* CSS for the sidebar links */
.sidebar-link {
  color: white;
  cursor: pointer;
  margin: 4px;
  /* max-width: 150px; */
}

.sidebar-link:hover {
  color: #890000;
  cursor: pointer;
  margin: 4px;
}

.sidebar-link:active {
  color: #e70000;
  cursor: pointer;
  margin: 4px;
}

.sidebar-link-light {
  color: black;
  cursor: pointer;
  margin: 4px;
}

.sidebar-link-light:hover {
  color: #890000;
  cursor: pointer;
  margin: 4px;
}

.sidebar-link-light:active {
  color: rgb(205, 43, 43);
  cursor: pointer;
  margin: 4px;
}

.sidebar-header {
  display: grid;
  grid-template-columns: auto 40px;
  cursor: pointer;
  align-items: center;
  height: 3px;
}

.sidebar-header p {
  color: white;
  text-align: center;
  font-weight: 800;
  margin: 0;
  margin-left: 6px;
  user-select: none;
}

.sidebar-header p:active {
  color: rgba(255, 255, 255, 0.567);
  margin: 0;
  margin-left: 6px;
  margin-top: 1px;
}

.sidebar-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2px;
  margin-left: 8px;
  margin-right: 2px;
  margin-top: 4px;
  margin-bottom: 8px;
  line-height: 1.3em;
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}

.sidebar-button {
  cursor: pointer;
  position: absolute;
  font-weight: 800;
  margin: 0;
  padding: 8px 4px 4px 6px;
  left: 150px;
  height: 35px;
  width: 40px;
  z-index: 5;
  transition: left 0.2s ease;
  user-select: none;
  font-size: 1rem;
  align-self: end;
  justify-self: center;
  text-align: left;
  text-shadow: #890000 1px 2px 1px;
}

.sidebar-button:hover {
  opacity: 0.5;
}

.sidebar-button:active {
  opacity: 0.2;
}

.sidebar-openbox {
  position: fixed;
  top: -2px;
  left: -2px;
  height: 30px;
  border-radius: 1px;
  display: grid;
  justify-items: start;
  z-index: 10;
}