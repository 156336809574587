/* CSS for the main app container */
.App {
  display: grid;
  justify-items: center;
  color: white;
}
/* CSS for the actual text container */
.text-container {
  overflow-y: hidden;
  margin-top: 32px;
  margin-bottom: 32px;
  line-height: 1.5rem;
  transition: 0.25s all ease-in;
  width: 100%;
  display: grid;
  justify-content: center;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #890000cd; 
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #e70000;
}
