.chapter-title-header {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  /*max-width: 440px;*/
  max-width: 100vw;
  margin-bottom: 32px;
}

.xenogears-title {
  font-family: "Xenogears", source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
  max-width: 350px;
  color: #890000;
  margin-top: 75px;
  margin-bottom: 100px;
}

.chapter-text {
  contain-intrinsic-height: 1000px;
  content-visibility: auto;
  font-size: 1.0rem;
  justify-self: center;
  margin-top: 32px;
  margin-left: 20px;
  max-width: 100vw;
  min-width: auto;
  position: relative;
  white-space: pre-wrap;
  width: fit-content;
  /*width: 450px;*/
}

.chapter-text-intro {
  color: white;
  font-size: 1.1rem;
  white-space: pre-wrap;
  margin-bottom: 32px;
  column-count: 2;
  column-gap: 25px;
  justify-self: center;
  max-width: 100vw;
  display: grid;
  justify-items: start;
  grid-template-rows: repeat(36, 1fr);
  grid-auto-flow: column;
}

.chapter-text-centered {
  color: white;
  font-size: 1.1rem;
  white-space: pre-wrap;
  /*max-width: 615px;*/
  max-width: 100vw;
  min-width: 339px;
  /* margin-top: 32px; */
  margin-bottom: 32px;
  text-align: center;
  justify-self: center;
}

.chapter-0 {
  color: white;
  font-size: 1.1rem;
  white-space: pre-wrap;
  /*float: left;*/
}

.download-link {
  text-decoration: underline;
  color: #890000;
  cursor: pointer;
  margin: 8px;
}

.download-link:hover {
  color: #e70000;
}

.download-link:active {
  color: #e70000;
}

/* Expand Chapter Button */
.chapter-button {
  background-color: #890000;  
  opacity: 0.8;
  font-size: 1.0rem;
  font-weight: 700;
  color: white;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  left: -32px;
  top: 22px;
  width: 20px;
  height: 20px;
  padding: 0;
  position: absolute;
  text-align: center;
}
.chapter-button:hover {
  background-color: #e70000;
}


/* Collapsable Chapters */
.chapter-hidden {
  max-height: 50px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.chapter-visible {
  overflow-x: hidden;
}

/* Rules for BIG screens only */
@media (min-width: 540px) {
  .chapter-text {
    margin-left: 0px;
    font-size: 1.1rem;
  }
  .chapter-text-intro {
    column-count: 3;
    grid-template-rows: repeat(24, 1fr);
  }

  .chapter-text-centered {
    font-size: 1.1rem;
  }

  .chapter-0 {
    font-size: 1.1rem;
  }

  .chapter-button {
    font-size: 1.2rem;
  }
}
